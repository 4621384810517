import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { LevitySession } from 'next-auth';
import { getServerSession } from 'next-auth';
import React from 'react';

import { redirectInfo, signInWithGoogle, useEmailAuth } from '@/levity/accounts';
import { env } from '@/lib/env';
import { authOptions } from '@/server/helpers/custom-auth.helpers';
import { Layout } from '@/ui-library';
import { buildQueryString, URLs } from '@/utils/urls';

import { AuthPanel } from '../../components/auth-panel';
import { MagicLinkPanel } from '../../components/magic-link-sent';

type ServerSideProps = InferGetServerSidePropsType<typeof getServerSideProps>;

function SignInPage(_props: ServerSideProps) {
  const { query } = useRouter();
  const emailCallbackUrl = `${env.NEXT_PUBLIC_NEXTAUTH_URL}${URLs.Login().pathname}${buildQueryString(query)}`;

  const {
    authenticate: authenticateWithEmail,
    email,
    emailProvider,
    error,
    isLoading,
    isMagicLinkSent,
  } = useEmailAuth();

  if (isMagicLinkSent)
    return (
      <Layout.Page>
        <Head>
          <title>Sign in to Levity</title>
        </Head>

        <MagicLinkPanel email={email} emailProvider={emailProvider} />
      </Layout.Page>
    );
  return (
    <Layout.Page>
      <Head>
        <title>Sign in to Levity</title>
      </Head>

      <Image src='/imgs/levity-logo.svg' width={155} height={47} alt='Levity logo' />
      <div className='h-12' />

      <AuthPanel
        copy={{
          heading: 'Sign in',
          subheading: "Don't have an account?",
          or: 'Or sign in with',
          emailButton: 'Sign in',
          emailSupportText: error,
        }}
        toggleAuthLink={{
          href: `${URLs.Signup().pathname}${buildQueryString(query)}`,
          text: 'Sign up',
        }}
        emailAuth={{
          isLoading,
          disabled: isLoading,
          onClick: () => {},
          onAuthenticate: (values) => authenticateWithEmail(values.email, emailCallbackUrl),
        }}
        googleAuth={{
          onAuthenticate: signInWithGoogle,
        }}
      />
    </Layout.Page>
  );
}

export default SignInPage;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const {
    req,
    res,
    query,
    query: { inviteToken },
  } = context;
  const session = (await getServerSession(req, res, authOptions)) as LevitySession;

  if (session && inviteToken) {
    return {
      redirect: {
        destination: `${URLs.WorkspaceInvitation().pathname}${buildQueryString(query)}`,
        permanent: false,
      },
    };
  }

  if (session && !inviteToken) return redirectInfo(session.access_token, context.query);

  return {
    props: {
      session,
    },
  };
}
